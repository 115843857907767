exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-french-wines-js": () => import("./../../../src/pages/french-wines.js" /* webpackChunkName: "component---src-pages-french-wines-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-italy-wines-js": () => import("./../../../src/pages/italy-wines.js" /* webpackChunkName: "component---src-pages-italy-wines-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-spain-wines-js": () => import("./../../../src/pages/spain-wines.js" /* webpackChunkName: "component---src-pages-spain-wines-js" */),
  "component---src-pages-us-partners-js": () => import("./../../../src/pages/us-partners.js" /* webpackChunkName: "component---src-pages-us-partners-js" */),
  "component---templates-blog-template-js": () => import("./../../../templates/blogTemplate.js" /* webpackChunkName: "component---templates-blog-template-js" */),
  "component---templates-domaine-template-js": () => import("./../../../templates/domaineTemplate.js" /* webpackChunkName: "component---templates-domaine-template-js" */),
  "component---templates-region-template-js": () => import("./../../../templates/regionTemplate.js" /* webpackChunkName: "component---templates-region-template-js" */),
  "component---templates-wine-template-js": () => import("./../../../templates/wineTemplate.js" /* webpackChunkName: "component---templates-wine-template-js" */)
}

